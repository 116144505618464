<template>
  <div>
    <b-button
      class="btn-icon btn-purple ml-1"
      style="padding: 11px !important"
      size="sm"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      v-b-tooltip.hover.v-primary
      title="Attack Surface Scan"
      @click="performAttackSurface()"
      >Attack Surface Scan</b-button
    >
    <b-modal
      v-model="openPerformAttackSurfaceModal"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <PerformAttackSurfaceScan :closeModal="closePerformAttackSurface" />
    </b-modal>
  </div>
</template>
  <script>
import { VBTooltip, BButton, BModal } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import useAppConfig from "@core/app-config/useAppConfig";
import PerformAttackSurfaceScan from "../../../../../views/AttackSurface/components/performAttackSurfaceScan.vue";
export default {
  components: {
    BButton,
    BModal,
    PerformAttackSurfaceScan,
  },

  directives: {
    "b-tooltip": VBTooltip,

    Ripple,
  },
  setup() {
    // const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app;
    const { skin } = useAppConfig();
    return {
      // App Name
      // appLogoImage,
      // appDarkLogoImage,
      skin,
      openPerformAttackSurfaceModal: false,
    };
  },
  methods: {
    performAttackSurface() {
      this.openPerformAttackSurfaceModal = true;
    },
    closePerformAttackSurface() {
      this.openPerformAttackSurfaceModal = false;
    },
  },
  //   mixins: [ResponseMixins],
};
</script>
<style scoped>
.btn-purple {
  border-color: #a020f0 !important;
  background-color: #a020f0 !important;
}
</style>