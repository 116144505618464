<template>
  <b-nav-item-dropdown
    ref="notificationDropdown"
    class="dropdown-notification ml-25"
    menu-class="dropdown-menu-media"
    left
  >
    <template #button-content>
      <feather-icon
        :badge="newNotifications.length"
        badge-classes="bg-danger"
        class="text-primary"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header border-bottom-0">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-button size="sm" variant="flat-primary" @click="handleViewMoreClick">
          <span class="align-middle">See more</span
          ><feather-icon icon="ExternalLinkIcon" class="ml-50" />
        </b-button>
      </div>
    </li>

    <!-- Notifications -->
    <div class="px-1">
      <b-tabs v-model="tabIndex" align="left">
        <b-tab title="View All" />
        <b-tab>
          <template slot="title">
            Unread
            <b-badge size="sm" pill variant="primary">{{
              newNotifications.length
            }}</b-badge>
          </template>
        </b-tab>
      </b-tabs>
    </div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      style="max-height: 50vh"
      tagname="li"
    >
      <!-- Account Notification -->
      <!-- <b-link
        v-for="notification in notifications"
        :key="notification.notification_id"
        @click="handleNotificationClick(notification)"
      >
        <div
          class="d-flex flex-row align-items-center justify-content-start py-75 px-1 border-bottom cursor-pointer"
          style="width: 100%"
        >
          <div class="d-flex align-items-center justify-content-center mr-1">
            <div
              class="d-flex align-items-center justify-content-center border"
              style="width: 49px; height: 49px; border-radius: 50%"
            >
              <feather-icon
                size="20"
                class="text-dark"
                :icon="
                  getIconFromNotificationType(notification.notification_type)
                "
              />
            </div>
          </div>

          <div
            class="w-100 d-flex flex-column align-items-start justify-content-center"
          >
            <div
              class="w-100 d-flex flex-row align-items-center justify-content-between mb-50"
            >
              <div
                class="d-flex flex-row align-items-center justify-content-start"
              >
                <b-badge variant="primary" pill class="px-75"
                  ><span class="font-weight-bold">{{
                    getTextFromNotificationType(notification.notification_type)
                  }}</span></b-badge
                >
                <p class="text-muted mb-0 ml-1">
                  {{ formatTimeStampToDate(notification.created_at) }}
                </p>
              </div>

              <div class="d-flex algn-items-center justify-content-end">
                <div
                  v-if="notification.mark_as_seened != true"
                  style="width: 13px; height: 13px; border-radius: 50%"
                  class="bg-success bg-lighten-1"
                ></div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-start">
              <p class="mb-0 text-dark font-weight-bold">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>
      </b-link> -->

      <template v-if="tabIndex == 0">
        <notification-list
          v-for="notification in notifications"
          :key="notification.notification_id"
          :notification="notification"
          :handleNotificationClick="handleNotificationClick"
          :closeNotificationDropdown="closeNotificationDropdown"
        />
      </template>

      <template v-if="tabIndex == 1">
        <notification-list
          v-for="notification in newNotifications"
          :key="notification.notification_id"
          :notification="notification"
          :handleNotificationClick="handleNotificationClick"
          :closeNotificationDropdown="closeNotificationDropdown"
        />
      </template>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="handleMarkAllNotificationAsRead"
        ><feather-icon icon="CheckCircleIcon" class="mr-50" />Mark all
        read</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "../../../../components/feather-icon/FeatherIcon.vue";
import ResponseMixins from "../../../../../mixins/ResponseMixins";
import NotificationMixins from "../../../../../mixins/NotificationMixins";
import NotificationList from "./NotificationList.vue";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    FeatherIcon,
    BTabs,
    BTab,
    NotificationList,
  },
  directives: {
    Ripple,
  },
  mixins: [ResponseMixins, NotificationMixins],
  setup() {
    /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: "Congratulation Sam 🎉",
    //     avatar: require("@/assets/images/avatars/6-small.png"),
    //     subtitle: "Won the monthly best seller badge",
    //     type: "light-success",
    //   },
    //   {
    //     title: "New message received",
    //     avatar: require("@/assets/images/avatars/9-small.png"),
    //     subtitle: "You have 10 unread messages",
    //     type: "light-info",
    //   },
    //   {
    //     title: "Revised Order 👋",
    //     avatar: "MD",
    //     subtitle: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    //     type: "light-danger",
    //   },
    // ];
    /* eslint-disable global-require */

    // const systemNotifications = [
    //   {
    //     title: "Server down",
    //     subtitle: "USA Server is down due to hight CPU usage",
    //     type: "light-danger",
    //     icon: "XIcon",
    //   },
    //   {
    //     title: "Sales report generated",
    //     subtitle: "Last month sales report generated",
    //     type: "light-success",
    //     icon: "CheckIcon",
    //   },
    //   {
    //     title: "High memory usage",
    //     subtitle: "BLR Server using high memory",
    //     type: "light-warning",
    //     icon: "AlertTriangleIcon",
    //   },
    // ];

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
  data() {
    return {
      tabIndex: 0,
    };
  },

  watch: {
    tabIndex(newValue) {
      console.log("Tab Index", newValue);
    },
  },

  mounted() {},
  computed: {
    newNotifications() {
      return this.notifications.filter(
        (notification) => notification.mark_as_seened === false
      );
    },
    readNotifications() {
      return this.notifications.filter(
        (notification) => notification.mark_as_seened === true
      );
    },
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    handleNotificationClick: {
      type: Function,
      required: true,
    },
    handleMarkAllNotificationAsRead: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleViewMoreClick() {
      this.closeNotificationDropdown();
      this.$router.push("/inbox");
    },

    closeNotificationDropdown() {
      this.$refs.notificationDropdown.hide();
    },
    getIconFromNotificationType(type) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (type) {
        case `REPORT`:
          return "BookIcon";
        case "TASK":
          return "BookIcon";
        case "SCAN":
          return "BookIcon";
        case "VULN":
          return "BookIcon";
        case "BASELINE":
          return "BookIcon";
        case "DOMAINSCAN":
          return "BookIcon";
        case "ASSET":
          return "CrossHairIcon";
        default:
          return "BookIcon";
      }
    },
    getTextFromNotificationType(type) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (type) {
        case `REPORT`:
          return "Report";
        case "TASK":
          return "Task";
        case "SCAN":
          return "Scan";
        case "VULN":
          return "Vulnerabilities";
        case "BASELINE":
          return "Baseline";
        case "DOMAINSCAN":
          return "Domain Scan";
        case "ASSET":
          return "Asset";  
        default:
          return "Unknown";
      }
    },
    formatTimeStampToDate(timestamp) {
      return `${this.getMonthTextFromDate(timestamp)} ${new Date(
        timestamp
      ).getDate()}, ${new Date(timestamp).getFullYear()}`;
    },

    getMonthTextFromDate(data) {
      const num = new Date(data).getMonth();
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = months[num];
      return month;
    },
  },
};
</script>

<style scoped></style>
