<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <AppSwitcher class="d-none d-lg-block" />
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown
        class="d-none d-lg-block"
        :notifications="notifications"
        :handleNotificationClick="handleNotificationClick"
        :handleMarkAllNotificationAsRead="handleMarkAllNotificationAsRead"
      />
      <QuickActionsDropdown class="d-none d-lg-block" />
      <ASMScan class="d-none d-lg-block"/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <div class="d-flex"> -->
      <!-- <label v-if="is_staff" class="mr-1">Organization</label> -->
      <!-- <QuickActionsDropdown class="mr-1" /> -->
      <!-- {{organizations}} -->
      <!-- v-if="is_staff" -->
      <v-select
        ref="orgSelect"
        v-model="org_id"
        label="text"
        :options="organizations"
        @input="onOrgChange()"
        autocomplete
        :reduce="(org_id) => org_id.value"
        :disabled="checkIfOrganizationShouldBeDisabled()"
        class="select-size-md mr-1"
        style="min-width: 300px"
      />
      <!-- {{ workspaces }} -->

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.app.user.fullname }}
            </p>
            <span class="user-status">{{ currentRole }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            
            class="badge-minimal"
            badge-variant="success"
            :text="avatarText($store.state.app.user.fullname )"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/inbox')"
        >
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span class="align-middle">Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/user/reset-password')"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span class="align-middle small">Reset Password</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!this.$store.state.app.user.is_staff"
          link-class="d-flex align-items-center"
          @click="$router.push('/wallet')"
        >
          <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
          <span>Credit Balance</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <!-- </div> -->
    </b-navbar-nav>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import NotificationDropdown from "../../@core/layouts/components/app-navbar/components/NotificationDropdown.vue";
import NotificationMixins from "../../mixins/NotificationMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import QuickActionsDropdown from "@/@core/layouts/components/app-navbar/components/QuickActionsDropdown.vue";
import AppSwitcher from "@/@core/layouts/components/app-navbar/components/AppSwitcher.vue";
import ASMScan from "@/@core/layouts/components/app-navbar/components/ASMScan.vue"

import { avatarText } from "@core/utils/filter";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormSelect,
    vSelect,
    BFormGroup,
    // Navbar Components
    DarkToggler,
    NotificationDropdown,
    QuickActionsDropdown,
    AppSwitcher,
    ASMScan
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      organizations: this.$store.state.app.organizations,
      organizationList: [],
      // organization: null,
      // organizations: [],
      is_staff: this.$store.state.app.user.is_staff,
      org_id: this.$store.state.app.org_id,
      workspaces: this.$store.state.app.workspaces,
      role: this.$store.state.app.user.role,
      currentRole:this.$store.state.app.user.current_groups[0].name,
      // org_id:null,,
      avatarText
    };
  },
  mixins: [NotificationMixins, ResponseMixins],
  computed: {
    // a computed getter
    admin: function () {
      // `this` points to the vm instance
      console.log(this.$store.getters);
      if (this.$store.getters["app/isAdmin"] == true) return true;
      else return false;
    },
    getRole: function () {
      // `this` points to the vm instance
      console.log(this.$store.getters);
      if (this.$store.state.app.user.groups.length) {
        return this.$store.state.app.user.groups[0].name;
      } else {
        if (this.$store.getters["app/isAdmin"] == true) return "Admin";
        else return "User";
      }
    },
    notifications: {
      get() {
        return this.$store.state.notification.allNotifications;
      },
    },
  },
  watch: {
    organizations: {
      handler() {
        this.$nextTick(this.adjustSelectWidth);
      },
      deep: true,
    },
  },
  // destroy(){
  //   let channel = this.$store.state.app.user.id + "_secusy_channel";
  //   this.sockets.unsubscribe(channel);
  // },
  mounted() {
    console.log(this.$store.state.app.token);
    console.log(this.$store.state.app.org_id);
    console.log(this.$store.state.app.user.id);
    if (
      this.$store.state.app.token &&
      this.$store.state.app.token != null &&
      this.$store.state.app.org_id &&
      this.$store.state.app.org_id != null &&
      this.$store.state.app.user.id &&
      this.$store.state.app.user.id != null
    ) {
      this.$store.dispatch("notification/getAllNotifications");
      let channel =
        "tm_" +
        this.$store.state.app.org_id +
        "_user_" +
        this.$store.state.app.user.id +
        "_channel";
      console.log("Socket Channel:", channel);
      this.sockets.subscribe(channel, (data) => {
        // this.getNotificationAndSetData(data);
        this.$store.dispatch("notification/getAllNotifications", data);
      });
    }
    this.load();
  },
  // mounted() {
  //   this.load();
  // },

  methods: {
    adjustSelectWidth() {
      const selectEl = this.$refs.orgSelect.$refs.input.$el;
      const contentWidth = selectEl.scrollWidth;
      selectEl.style.width = `${Math.max(contentWidth, 300)}px`;
    },
    load() {
      this.loadOrg();
      // this.loadOrgList();
    },

    checkIfOrganizationShouldBeDisabled() {
      // "/assets/scan/" "/vulnerability/detail/"
      const disabledPaths = [];
      if (disabledPaths.some((path) => this.$route.path.match(path))) {
        return true;
      } else {
        return false;
      }
    },
    handleMarkAllNotificationAsRead() {
      this.$store
        .dispatch("notification/markAllNotificationsAsRead")
        .then((res) => {
          this.$store
            .dispatch("notification/getAllNotifications", {})
            .then(() => {
              this.$store.dispatch("notification/getNotifications", {
                page: 1,
                mark_as_seened: false,
              });
            });
        })
        .catch((err) => {
          // this.handleError(err);
          console.log(err);
        });
    },

    handleNotificationClick(notification) {
      this.$store
        .dispatch(
          "notification/markNotificationAsRead",
          notification.notification_id
        )
        .then((res) => {
          const newRoute = this.getRouteFromNotificationType(notification);
          this.$router.push(newRoute);
          this.$store
            .dispatch("notification/getAllNotifications", {})
            .then(() => {
              this.$store.dispatch("notification/getNotifications", {
                page: 1,
              });
            });
        })
        .catch((err) => {
          // this.handleError(err);
          console.log(err);
        });
    },

    logout: function () {
      this.$store.dispatch("notification/resetNotifications");
      this.$store.dispatch("app/logout");
      if (this.$route.name != "login") this.$router.push("/login");
    },
    onOrgChange: function () {
      // if (this.is_staff) {
      // debugger
      // alert(this.org_id ,"hello")
      this.$store.dispatch("app/getOrg", this.org_id);
      this.$store.dispatch("app/currenUser");

      this.workspaces.map((item) => {
        if (item.tenant_uid === this.org_id) {
          this.$store.commit("app/set_currentWorkspace", item);
          const currentmodule = [];
          item.enabled_modules.map((mod) => {
            currentmodule.push(mod.name);
          });
          this.$store.commit("app/set_currentModules", currentmodule);
          this.$store.dispatch("notification/getAllNotifications", {});
        }
      });
      // }
    },

    loadOrg: function () {
      this.getWorkSpaces();
      // alert(this.$store.state.app.organizations.length)
      // console.log("Organization in store",this.$store.state.app.organizations)
      // if (this.$store.state.app.user.id == 1) {
      //   if (this.$store.state.app.organizations.length == 1) {
      //     const o_options = {
      //       method: "GET",
      //       headers: { "content-type": "application/json" },
      //       url:
      //         process.env.VUE_APP_BASEURL +
      //         "organization/organization/get-all-org",
      //     };
      //     var self = this;
      //     this.$http(o_options).then((res) => {
      //       res.data.map(function (value, key) {
      //         let org = {
      //           value: res.data[key].sso_ref_id,
      //           text: res.data[key].org_name,
      //           id: res.data[key].org_id,
      //         };
      //         self.organizations.push(org);
      //       });
      //       self.$store.dispatch("app/setOrgsInStore", self.organizations);
      //     });
      //   }
      // } else {
      //   this.getWorkSpaces();
      // }
    },
    // loadOrgList() {
    //   const org_options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL + "organization/organization/get-all-org",
    //   };
    //   var self = this;
    //   this.$http(org_options).then((res) => {
    //     res.data.map(function (value, key) {
    //       let org = {
    //         value: res.data[key].org_id,
    //         text: res.data[key].org_name,
    //         refid:res.data[key].sso_ref_id,
    //       };
    //       self.organizationList.push(org);
    //     });
    //     self.$store.dispatch("app/setOrgListInStore", self.organizationList);
    //   });
    // },
    getWorkSpaces() {
      this.organizations = this.workspaces.map((workspace) => ({
        value: workspace.tenant_uid,
        text: workspace.name,
      }));
      // var self = this;
      // this.workspaces.forEach(function (value, key) {
      //   let org = {
      //     value: this.workspaces[key].tenant_uid,
      //     text: this.workspaces[key].name,
      //   };
      //   self.organizations.push(org);
      // });
    },
  },
  beforeDestroy() {
    let channel =
      "tm_" +
      this.$store.state.app.org_id +
      "_user_" +
      this.$store.state.app.user.id +
      "_channel";
    this.sockets.unsubscribe(channel);
    console.log("Un-Subscribed from channel", channel);
  },
};
</script>
<style lang="scss">
//@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>
