<template>
  <b-nav-item-dropdown
    ref="quickActionsDropdown"
    class="dropdown-notification my-50"
    menu-class="custom-menus px-1"
    left
  >
    <b-modal
      ref="quick_scan_selection_modal"
      hide-footer
      :title="currentScan.title"
      centered
      header-close-variant="danger"
      no-close-on-backdrop
      size="lg"
    >
      <validation-observer ref="quick_scan_selection" #default="{ invalid }">
        <b-form
          class="w-100 d-flex flex-column align-items-center justify-content-start"
          @submit.prevent="handleQuickScanSubmit"
        >
          <div class="w-75 mb-2">
            <p class="font-weight-bold mb-0 text-center">
              Select from any of the following two options to initiate the scan
            </p>
          </div>

          <b-form-group class="w-100 mb-1" label="Asset Group">
            <validation-provider vid="group" name="Group" #default="{ errors }">
              <b-form-select
                block
                v-model="currentScan.selectedGroupId"
                :options="groupOptions"
                :disabled="currentScan.selectedAssets.length != 0"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div
            class="w-100 d-flex flex-row align-items-center justify-content-center my-0"
          >
            <div style="width: 70px">
              <hr />
            </div>
            <h5 class="mb-0 mx-1">OR</h5>
            <div style="width: 70px">
              <hr />
            </div>
          </div>
          <b-form-group class="w-100 mb-0" label="Assets">
            <validation-provider
              vid="assets"
              name="Assets"
              #default="{ errors }"
            >
              <v-select
                :disabled="currentScan.selectedGroupId != null"
                placeholder="Select.."
                :filterable="false"
                v-model="currentScan.selectedAssets"
                multiple
                label="asset_nickname"
                :options="assetOptions"
                @search="assetOptionsSearch"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div
            class="w-100 d-flex align-items-center justify-content-center mt-2 mb-1"
          >
            <b-button
              :disabled="
                invalid ||
                currentScan.selectedGroupId == null &&
                currentScan.selectedAssets.length == 0
              "
              type="submit"
              size="sm"
              variant="success"
            >
              <b-spinner
                v-if="isLoading"
                label="Loading..."
                small
                class="mr-50"
              />
              <feather-icon v-else icon="CheckIcon" class="mr-50" /><span
                class="align-middle"
                >{{ isLoading ? "Please Wait" : "Initiate Scan" }}</span
              ></b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <template #button-content>
      <b-button variant="outline-primary" size="sm"
        ><feather-icon icon="ZapIcon" class="mr-50 text-primary" /><span
          class="align-midle"
          >Quick Actions</span
        ></b-button
      >
    </template>

    <div
      class="d-flex flex-row align-items-start justify-content-between w-100"
    >
      <div
        class="w-100 pb-50 py-25 d-flex flex-column align-items-start justify-content-start border-right"
      >
        <!-- Header 1 -->
        <li class="dropdown-menu-header border-bottom-0 mt-25">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-25 mr-auto">Quick Scans</h4>
          </div>
        </li>
        <div
          class="px-1 d-flex flex-column align-items-start justify-content-start"
        >
          <template v-for="scan in quickScans">
            <div
              :key="scan.title"
              @click="handleScanClick(scan)"
              class="custom-card d-flex align-items-center justify-content-between w-100 cursor-pointer text-truncate"
              style="height: 60px"
            >
              <div class="d-flex align-items-center justify-content-start mx-1">
                <feather-icon size="26" :icon="scan.icon" />
              </div>
              <div
                class="w-100 d-flex flex-column align-items-start justify-content-center pr-50"
              >
                <h5 class="mb-25 font-weight-bolder text-primary">
                  {{ scan.title }}
                </h5>
                <p class="mb-0">{{ scan.description }}</p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div
        class="pb-50 py-25 d-flex flex-column align-items-start justify-content-start h-100"
        style="min-width: 210px"
      >
        <!-- Header 2 -->
        <li class="dropdown-menu-header border-bottom-0">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-25 mr-auto">Go To</h4>
          </div>
        </li>

        <div
          class="px-1 d-flex flex-column align-items-center justify-content-start mb-50 w-100"
        >
          <template v-for="(item, i) in goTos">
            <div
              :key="i"
              @click="goToRoute(item.route)"
              class="custom-card w-100 px-50 py-25 mb-50 rounded bg-light-primary d-flex flex-row align-items-center justify-content-between cursor-pointer"
            >
              <div
                class="d-flex flex-row align-items-center justify-content-start"
              >
                <feather-icon :icon="item.icon" class="mr-50" /><span
                  class="align-middle"
                  >{{ item.title }}</span
                >
              </div>
              <div class="d-flex flex-row align-items-center">
                <feather-icon icon="ArrowRightIcon" class="mr-50" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </b-nav-item-dropdown>
</template>
  
<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BTabs,
  BTab,
  BModal,
  BForm,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "../../../../components/feather-icon/FeatherIcon.vue";
import ResponseMixins from "../../../../../mixins/ResponseMixins";
import NotificationList from "./NotificationList.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    FeatherIcon,
    BTabs,
    BTab,
    NotificationList,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [ResponseMixins],
  setup() {},

  data() {
    return {
      isLoading: false,
      assetOptionsLoading: false,
      quickScans: [
        {
          title: "Domain Discovery",
          description: "Discover your domains",
          route: "/Discovery?tabIndex=1",
          icon: "DatabaseIcon",
        },
        {
          title: "Email Breach",
          description: "Check email breach",
          route: "/Discovery?tabIndex=2",
          icon: "MailIcon",
        },
        {
          title: "Asset Discovery",
          description: "Discover your assets",
          route: "/Discovery?tabIndex=0",
          icon: "GridIcon",
        },
        {
          title: "Web application scan",
          description: "Scan your web application",
          route: "",
          icon: "GlobeIcon",
          type: "web",
        },
        {
          title: "Network Scan",
          description: "Network Scan",
          route: "",
          icon: "ApertureIcon",
          type: "network",
        },
        {
          title: "Check SSL score",
          description: "Check SSL score",
          route: "",
          icon: "ShieldIcon",
          type: "ssl",
        },
        {
          title: "IP Reputation",
          description: "IP Reputation Scan",
          route: "",
          icon: "MapPinIcon",
          type: "ipreputation",
        },
      ],

      goTos: [
        {
          title: "Assets",
          route: "/assets/",
          icon: "GridIcon",
        },
        {
          title: "Vulnerabilities",
          route: "/assets/vulnerabilities",
          icon: "AlertTriangleIcon",
        },
        {
          title: "Tasks",
          route: "/task/",
          icon: "EditIcon",
        },
        {
          title: "Scans",
          route: "/scans/",
          icon: "SearchIcon",
        },
      ],

      currentScan: {
        title: "",
        scanType: "",
        selectedAssets: [],
        selectedGroupId: null,
      },
      groupOptions: [{ text: "Select..", value: null }],
      assetOptions: [],
    };
  },

  watch: {},

  mounted() {
    if (this.$store.state.app.org_id) {
      this.load();
      //When the query params are loaded inside the route
      this.$router.onReady(() => {
        //Checks for quickAction Query in query params
        if (
          this.$route.query.hasOwnProperty("quickAction") ||
          (typeof this.$route.query.quickAction != "undefined" &&
            this.$route.query.quickAction !== null)
        ) {
          const type = this.$route.query.quickAction;
          let scanIndex = this.quickScans.findIndex(
            (scan) => scan.type == type
          );
          this.handleScanClick(this.quickScans[scanIndex]);
          this.$router.replace({ query: {} });
        }
      });
    }
  },

  computed: {},
  props: {},
  methods: {
    load() {
      this.getAssetGroupsAndSetData();
      this.getAssetsAndSetData();
    },

    assetOptionsSearch(search, loading) {
      if (search && search.length) {
        loading(true);
        this.getAssets(search)
          .then((res) => {
            this.assetOptions = res.data.results;
          })
          .finally(() => {
            loading(false);
          });
      }
    },

    getAssetsAndSetData() {
      this.getAssets().then((res) => {
        this.assetOptions = res.data.results;
      });
    },

    getAssets(query) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/asset",
          params: {
            name: query,
            query: true,
            org_id: this.$store.state.app.org_id,
          },
        };

        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    resetCurrentScan() {
      this.currentScan = {
        title: "",
        scanType: "",
        selectedAssets: [],
        selectedGroupId: null,
      };
    },

    handleQuickScanSubmit() {
      this.isLoading = true;
      const newData = {};
      console.log("Current Scan", this.currentScan);
      newData.scan_type = this.currentScan.scanType;
      if (this.currentScan.selectedGroupId == null) {
        newData.group_id = "";
        newData.assets = this.currentScan.selectedAssets.map(
          (asset) => asset.asset_id
        );
      } else {
        newData.group_id = this.currentScan.selectedGroupId;
        newData.assets = [];
      }
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/quick-scan/",
        data: JSON.stringify(newData),
      };

      this.$http(options)
        .then((res) => {
          this.hideQuickScanSelectionModal();
          this.handleResponse(res);
        })
        .catch((err) => {
          // this.handleError(err);
          // this.$refs["quick_scan_selection_modal"].hide();
          this.hideQuickScanSelectionModal();
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getAssetGroupsAndSetData() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/group/",
        params: {
          org_id: this.$store.state.app.org_id,
        },
      };

      this.$http(options).then((res) => {
        res.data.results.map((group) => {
          this.groupOptions.push({
            text: group.group_name,
            value: group.group_id,
          });
        });
      });
    },

    handleScanClick(scan) {
      if (scan.route && scan.route != "") {
        this.goToRoute(scan.route);
      } else {
        this.handleScanWithOptionsClick(scan.type, scan.title);
      }
    },

    handleScanWithOptionsClick(type, title) {
      this.resetCurrentScan();
      console.log("Scan Type", type);
      this.$set(this.currentScan, "scanType", type);
      this.$set(this.currentScan, "title", title);
      // this.currentScan.scanType = type
      // this.currentScan.title = title
      this.$refs.quickActionsDropdown.visible = false;
      this.showQuickScanSelectionModal();
    },

    showQuickScanSelectionModal() {
      this.$refs["quick_scan_selection_modal"].show();
    },

    hideQuickScanSelectionModal() {
      this.$refs["quick_scan_selection_modal"].hide();
    },

    async goToRoute(route) {
      this.$refs.quickActionsDropdown.visible = false;
      // this.$refs.quickActionsDropdown.$emit('hide')
      const currentPath = this.$route.path;
      if (currentPath == route || route.includes(currentPath)) {
        await this.$router.replace(route);
        this.$router.go(0);
      } else {
        this.$router.replace(route);
      }
    },
  },
};
</script>
  
<style lang="css">
.custom-menus {
  min-width: 40rem !important;
}

.custom-card {
  transition: all 0.2s linear;
}

.custom-card:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
  